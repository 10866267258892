// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --lighter: #ffffff;
  --gray: #ddd;
  --primary-color: #039942;

  /* radius */
  --md-radius: 8px;
}

.bx {
  font-size: 18px;
}

a {
  text-decoration: none;
}

/* scroll bar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: var(--gray);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(189, 189, 189);
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,wBAAwB;;EAExB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA,sBAAsB;AACtB;EACE,UAAU;EACV,YAAY;EACZ,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;AACrB","sourcesContent":[":root {\n  --lighter: #ffffff;\n  --gray: #ddd;\n  --primary-color: #039942;\n\n  /* radius */\n  --md-radius: 8px;\n}\n\n.bx {\n  font-size: 18px;\n}\n\na {\n  text-decoration: none;\n}\n\n/* scroll bar styles */\n::-webkit-scrollbar {\n  width: 5px;\n  height: 10px;\n  background-color: var(--gray);\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: rgb(189, 189, 189);\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
