import "./styles.css";

const Separator = (props) => {
  return (
    <div className="separator-container">
      <h1 className="text-bold">{props.title}</h1>
    </div>
  );
};

export default Separator;
