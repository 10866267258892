import "./styles.css";

const Input = (props) => {
  return (
    <div className="input-wrapper">
      {props.label && <label>{props.label}</label>}
      <div className={`input-container ${props.className}`}>
        {props.leftIcon && (
          <div className="input-icon-container">
            <i className={`bx ${props.leftIcon}`}></i>
          </div>
        )}
        <div className="input-content">
          <input
            className="form-control"
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChangeText}
            type={props.type}
          />
        </div>
        {props.rightIcon && (
          <div
            className={`input-icon-container ${props.classNameRight}`}
            onClick={props.handleRightIconPressed}
          >
            {props.buttonRight ? (
              <span>{props.buttonRight}</span>
            ) : (
              <i className={`bx ${props.rightIcon}`}></i>
            )}
          </div>
        )}
      </div>
      {props.error && <span className="text-danger">{props.error}</span>}
    </div>
  );
};

export default Input;
