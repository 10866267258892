// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}

.loader-container.sm {
  min-height: 10vh;
}

.loader-container.sm .loader {
  width: 30px;
  height: 30px;
  border: 3px solid var(--gray);
  border-bottom-color: var(--primary-color);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--gray);
  border-bottom-color: var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Loader/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,yCAAyC;EACzC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 20vh;\n}\n\n.loader-container.sm {\n  min-height: 10vh;\n}\n\n.loader-container.sm .loader {\n  width: 30px;\n  height: 30px;\n  border: 3px solid var(--gray);\n  border-bottom-color: var(--primary-color);\n}\n\n.loader {\n  width: 48px;\n  height: 48px;\n  border: 5px solid var(--gray);\n  border-bottom-color: var(--primary-color);\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
