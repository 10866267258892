import { Popup } from "react-leaflet";

const MarkerPopup = (props) => {
  return (
    <Popup>
      <div className="popup-container">
        <h1>{props.display_name}</h1>
        <div>
          {Object.keys(props?.administrationData || {}).map((key, k) => {
            return (
              <div key={k} className="ad_data">
                <div className="keyname">
                  <span>{key}:</span>
                </div>
                <span>{props.administrationData[key]}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

export default MarkerPopup;
