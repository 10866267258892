import { Loading } from "../../Loader";

const ListItem = (props) => {
  return (
    <div
      className="map-search-result-item"
      onClick={() => props.handleSelectLocation(props)}
    >
      <div className="header">
        <div className="icon">
          <i className="bx bx-map"></i>
        </div>
        <span>{props.display_name}</span>
      </div>

      <div className="content">
        {console.log("*******loader action---", props.isLoading)}
        {props.isLoading ? (
          <Loading className="sm" />
        ) : (
          props.administrationData && (
            <div className="administration-data">
              {Object.keys(props.administrationData).map((key, k) => {
                return (
                  <div className="item" key={k}>
                    <div>
                      <div className="keyname">
                        <span>{key}:</span>
                      </div>
                      <div>
                        <span>{props.administrationData[key]}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ListItem;
