import axios from "axios";
import toastMessage from "../../../utils/toastMessage";

export default async (address) => {
  try {
    const options = {
      method: "GET",
      url: `https://nominatim.openstreetmap.org/search.php?q=${address.toLowerCase().includes("rwanda")? address: address + " Rwanda"}&polygon_geojson=1&format=jsonv2&limit=1`,
    };

    const data = await axios(options);

    return data.data;
  } catch (error) {
    toastMessage("error", error);
  }
};
