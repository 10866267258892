// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper label {
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.input-container {
  display: flex;
  align-items: center;
  min-height: 45px;
  border: 2px solid var(--gray);
  border-radius: var(--md-radius);
  background-color: var(--lighter);
  cursor: default;
  position: relative;
  margin-bottom: 1rem;
}

.input-container .input-icon-container {
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.input-container .input-content {
  flex: 1 1;
}

.input-container input,
.input-container input:focus,
.input-container input:active {
  border: 0;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  padding-left: 1.5rem;
  background-color: transparent;
  font-size: 14px;
}

.input-icon-container.search .icon {
  font-size: 24px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Input/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;EAC7B,+BAA+B;EAC/B,gCAAgC;EAChC,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,SAAO;AACT;;AAEA;;;EAGE,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB","sourcesContent":[".input-wrapper label {\n  margin-bottom: 0.5rem;\n  font-weight: 400;\n}\n\n.input-container {\n  display: flex;\n  align-items: center;\n  min-height: 45px;\n  border: 2px solid var(--gray);\n  border-radius: var(--md-radius);\n  background-color: var(--lighter);\n  cursor: default;\n  position: relative;\n  margin-bottom: 1rem;\n}\n\n.input-container .input-icon-container {\n  min-height: 45px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 1rem;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n}\n\n.input-container .input-content {\n  flex: 1;\n}\n\n.input-container input,\n.input-container input:focus,\n.input-container input:active {\n  border: 0;\n  outline: none;\n  box-shadow: none;\n  border-radius: 0;\n  padding-left: 1.5rem;\n  background-color: transparent;\n  font-size: 14px;\n}\n\n.input-icon-container.search .icon {\n  font-size: 24px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
