import React from "react";
import { Map } from "../components/Map";
import search from "../components/Map/utils/search";
import RwandaGeocoder from "../components/Map/utils/RwandaGeocoder";
import toastMessage from "../utils/toastMessage";
import reverse from "../components/Map/utils/reverse";

class MapScreen extends React.Component {
  initStates = () => {
    return {
      address: "",
      isSubmitting: false,
      searchResult: [],
      addresses: [],
      selectedPosition: {},
      error: {},
      isSearchingByCoords: false,
      latitude_value: "",
      longitude_value: "",
      geocodingLoader: {},
    };
  };

  state = {
    ...this.initStates(),
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  onSearch = async () => {
    const { address } = this.state;

    if (address === "")
      return toastMessage(
        "error",
        "The address in the search field must contain valid information and cannot be left blank."
      );

    this.setState({
      isSubmitting: true,
      searchResult: [],
      latitude_value: "",
      longitude_value: "",
    });

    const searchResult = await search(address);

    this.handleSearchResults(searchResult);
  };

  handleSearchResults(searchResult, address) {
    const recenterPosition = searchResult.length > 0 ? searchResult[0] : {};
    console.log({ recenterPosition });

    this.setState({
      isSubmitting: false,
      searchResult,
      selectedPosition: recenterPosition,
      address: address || this.state.address,
      latitude_value: recenterPosition.lat + "" || "",
      longitude_value: recenterPosition.lon + "" || "",
    });

    this.handleMultipleResult(searchResult);
  }

  handleMultipleResult = async (locations) => {
    let addresses = [];

    for (let [i, el] of locations.entries()) {
      const position = {
        lat: parseFloat(el.lat),
        lon: parseFloat(el.lon),
      };

      const geoCodedResult = await this.handleGeoCode(i, position);

      addresses.push({
        ...el,
        ...position,
        administrationData: geoCodedResult,
      });
    }

    this.setState({ searchResult: addresses });
  };

  handleSelectLocation(location) {
    this.setState({ selectedPosition: location });
  }

  handleGeoCode = async (index, { lat, lon }) => {
    let { geocodingLoader } = this.state;

    geocodingLoader = {
      ["geocoding_" + index]: true,
    };

    this.setState({
      geocodingLoader,
    });

    const data = await RwandaGeocoder(lat, lon);

    geocodingLoader = {
      ["geocoding_" + index]: false,
    };

    this.setState({
      geocodingLoader,
    });

    return data;
  };

  handleReset() {
    this.setState({
      ...this.initStates(),
    });
  }

  handleGetCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.currentLocationSuccess,
        this.currentLocationError
      );
    } else {
      toastMessage("error", "Geolocation not supported");
    }
  }

  currentLocationSuccess = async (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    this.setState({ isSubmitting: true });

    const searchResult = await reverse({ lat, lng });

    this.handleSearchResults([searchResult], searchResult.display_name);
  };

  currentLocationError() {
    toastMessage("error", "Unable to retrieve your location");
  }

  validateForm() {
    let { error, latitude_value, longitude_value } = this.state;

    if (latitude_value === "" || latitude_value === 0) {
      error.latitude_value = "Latitude is mandatory";
    }
    if (longitude_value === "" || longitude_value === 0) {
      error.longitude_value = "Longitude is mandatory";
    }

    this.setState({ error });
  }

  handleSearchByCoords = async () => {
    await this.validateForm();

    let { error, latitude_value, longitude_value } = this.state;

    if (Object.keys(error).length > 0) return;

    this.setState({ isSubmitting: true });

    const searchResult = await reverse({
      lat: latitude_value,
      lng: longitude_value,
    });

    this.handleSearchResults([searchResult], searchResult.display_name);
  };

  render() {
    return (
      <>
        <Map
          showMainNavbar
          onChangeText={(field, e) => this.onChangeText(field, e)}
          address={this.state.address}
          onSearch={this.onSearch.bind(this)}
          searchResult={this.state.searchResult}
          isSubmitting={this.state.isSubmitting}
          handleSelectLocation={this.handleSelectLocation.bind(this)}
          addresses={this.state.searchResult}
          handleReset={this.handleReset.bind(this)}
          recenterPosition={this.state.selectedPosition}
          handleGetCurrentLocation={this.handleGetCurrentLocation.bind(this)}
          handleSearchByCoords={this.handleSearchByCoords.bind(this)}
          error={this.state.error}
          latitude_value={this.state.latitude_value}
          longitude_value={this.state.longitude_value}
          isSearchingByCoords={this.state.isSearchingByCoords}
          geocodingLoader={this.state.geocodingLoader}
        />
      </>
    );
  }
}

export default MapScreen;
