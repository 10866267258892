// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  height: 100vh;
  z-index: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  overflow-y: hidden;
}

.leaflet-container {
  height: 100vh;
  pointer-events: painted;
}

.map-marker {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  border: 3px solid var(--lighter);
}

.popup-container h1 {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--gray);
}

.popup-container .keyname {
  text-transform: capitalize;
  min-width: 100px;
}

.popup-container .ad_data {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.popup-container .keyname span {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Map/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,MAAM;EACN,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sCAAsC;EACtC,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,oCAAoC;AACtC;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".map-container {\n  height: 100vh;\n  z-index: 0;\n  width: 100%;\n  position: absolute;\n  bottom: 0;\n  top: 0;\n  overflow-y: hidden;\n}\n\n.leaflet-container {\n  height: 100vh;\n  pointer-events: painted;\n}\n\n.map-marker {\n  width: 40px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--primary-color);\n  border-radius: 50%;\n  border: 3px solid var(--lighter);\n}\n\n.popup-container h1 {\n  font-size: 14px;\n  font-weight: 700;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid var(--gray);\n}\n\n.popup-container .keyname {\n  text-transform: capitalize;\n  min-width: 100px;\n}\n\n.popup-container .ad_data {\n  display: flex;\n  align-items: center;\n  min-height: 30px;\n}\n\n.popup-container .keyname span {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
