import { Link } from "react-router-dom";
const CurrentLocation = (props) => {
  return (
    <div
      className="current-location-container"
      onClick={props.handleGetCurrentLocation}
    >
      <div className="curLoc">
        <i className="bx bx-current-location"></i>
      </div>
      <Link to="#" className="text-bold">
        Use Current Location
      </Link>
    </div>
  );
};

export default CurrentLocation;
