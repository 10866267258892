import "./styles.css";

const Button = (props) => {
  return (
    <button
      onClick={props.onPress}
      className={`btn btn-primary ${props.className}`}
      disabled={props.isSubmitting}
    >
      {props.isSubmitting ? "Please wait..." : props.text}
    </button>
  );
};

export default Button;
