import { Loading } from "../../Loader";
import "../styles.css";
import ListItem from "./ListItem";

const SearchDropdownContainer = (props) => {
  return (
    <>
      {props.isSubmitting ? (
        <Loading />
      ) : (
        props?.searchResult?.length > 0 && (
          <div className="map-search-result-container">
            {props.searchResult.map((el, i) => {
              return (
                <ListItem
                  key={i}
                  {...el}
                  {...props}
                  isLoading={
                    props.geocodingLoader &&
                    props.geocodingLoader["geocoding_" + i]
                  }
                />
              );
            })}
          </div>
        )
      )}
    </>
  );
};

export default SearchDropdownContainer;
