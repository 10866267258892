import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "./styles.css";
import MapMarker from "./MapMarker";
import { SearchInput } from "../Search";
import RecenterMap from "./utils/RecenterMap";

const Map = (props) => {
  return (
    <div className="map-container">
      {props.showMainNavbar && <SearchInput {...props} />}
      <MapContainer
        scrollWheelZoom={true}
        zoomControl={false}
        center={[-1.959, 30.158667]}
        zoom={16}
        keyboard={false}
        animate={true}
        easeLinearity={0.35}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.addresses &&
          props.addresses.length > 0 &&
          props.addresses.map((marker, m) => {
            return <MapMarker marker={marker} key={m} />;
          })}
        {Object.keys(props.recenterPosition || {}).length > 0 && (
          <RecenterMap {...props.recenterPosition} />
        )}
      </MapContainer>
    </div>
  );
};

export default Map;
