import axios from "axios";
import toastMessage from "../../../utils/toastMessage";

export default async ({ lat, lng }) => {
  try {
    const options = {
      method: "GET",
      url: `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lng}&zoom=18&format=jsonv2&limit=1`,
    };

    const data = await axios(options);

    return data.data;
  } catch (error) {
    toastMessage("error", error);
  }
};
