import "./styles.css";

const Loading = (props) => {
  return (
    <div className={`loader-container ${props.className}`}>
      <span className="loader"></span>
    </div>
  );
};

export default Loading;
