import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//screens
import MapScreen from "./app/screen/MapScreen";

const createBrowserHistory = require("history").createBrowserHistory;

function App() {
  return (
    <div className="App">
      <Router history={createBrowserHistory({ queryKey: false })}>
        <Switch>
          <Route exact path="/" component={MapScreen} />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </div>
  );
}

export default App;
