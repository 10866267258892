// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  background-color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  font-weight: 600;
  font-size: 13px;
}

.btn:focus,
.btn:active {
  border: 2px solid var(--primary-color) !important;
  outline: none;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Button/styles.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,iDAAiD;EACjD,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;EAEE,iDAAiD;EACjD,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".btn {\n  background-color: var(--primary-color) !important;\n  border: 2px solid var(--primary-color) !important;\n  font-weight: 600;\n  font-size: 13px;\n}\n\n.btn:focus,\n.btn:active {\n  border: 2px solid var(--primary-color) !important;\n  outline: none;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
