import axios from "axios";
import toastMessage from "../../../utils/toastMessage";

export default async (lat, lon) => {
  try {
    const options = {
      method: "POST",
      url: `https://api.huzalytics.com/api/huzalytics/get_village_lat_long`,
      data: {
        latitude: lat,
        longitude: lon,
      },
    };

    const data = await axios(options);

    return data.data;
  } catch (error) {
    toastMessage("error", error);
  }
};
