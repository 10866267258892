import CurrentLocation from "./CurrentLocation";
import SearchDropdownContainer from "./SearchDropdownContainer/Container";
import "./styles.css";
import { Input } from "../Input";
import { Button } from "../Button";
import Separator from "../Separator/Separator";

const SearchInput = (props) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter key press
      event.preventDefault(); // Prevent the default form submission behavior
      console.log("Enter key pressed");
      props.onSearch();
    }
  };
  return (
    <div className="mainNavbar">
      <div className="row">
        <div
          className="col-md-4 search-col"
          style={{
            pointerEvents: "pointed",
            height: "100vh",
            paddingBottom: 80,
            overflowY: "auto",
            paddingRight: 2,
          }}
        >
          <div className="search-item-content">
            <div className="search-section">
              <div className="search-input">
                <input
                  className="form-control"
                  placeholder="Search address..."
                  value={props.address}
                  onChange={(e) => props.onChangeText("address", e)}
                  onFocus={props.showOnFocus}
                  onKeyDown={handleKeyPress}
                />
                <div className="icons-actions">
                  {props.address !== "" && (
                    <div
                      className="press_enter_container"
                      onClick={props.onSearch}
                    >
                      <span className="press_enter">Press Enter</span>
                    </div>
                  )}

                  <div className="icon-item" onClick={props.onSearch}>
                    <i className="bx bx-search"></i>
                  </div>
                  {props.address !== "" && (
                    <div className="icon-item" onClick={props.handleReset}>
                      <i className="bx bx-x"></i>
                    </div>
                  )}
                </div>
              </div>
              <div className="search-content" style={{ padding: 0 }}>
                <SearchDropdownContainer {...props} />
              </div>
            </div>
            <div className="search-section">
              <div className="search-item-content">
                <div className="search-content" style={{ paddingBottom: 0 }}>
                  <CurrentLocation
                    handleGetCurrentLocation={props.handleGetCurrentLocation}
                  />
                </div>
                <div className="line-separator" />
                <div className="search-content">
                  <Separator title="Search Village by Coordinates - Rwanda" />
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        label="Latitude"
                        type="number"
                        value={props.latitude_value}
                        onChangeText={(e) =>
                          props.onChangeText("latitude_value", e)
                        }
                        placeholder="0"
                        error={props.error.latitude_value}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label="Longitude"
                        type="number"
                        value={props.longitude_value}
                        onChangeText={(e) =>
                          props.onChangeText("longitude_value", e)
                        }
                        placeholder="0"
                        error={props.error.latitude_value}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Button
                        text="Search"
                        isSubmitting={props.isSearchingByCoords}
                        onPress={props.handleSearchByCoords}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12 center-text"
                      style={{ fontSize: 12 }}
                    >
                      Powered by HuzaLabs
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
