// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.separator-container {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.separator-container h1 {
  font-size: 14px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Separator/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".separator-container {\n  min-height: 40px;\n  display: flex;\n  align-items: center;\n}\n\n.separator-container h1 {\n  font-size: 14px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
