import React from "react";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import "./styles.css";
import MarkerPopup from "./MarkerPopup";

export default function ThingMarker(props) {
  const map = useMap();
  return (
    <div>
      <Marker
        icon={L.divIcon({
          className: "leaflet-data-marker",
          html: `<div class='map-marker ' style='background-color:${props.color}'>
               <i class='bx ${props?.icon}'></i>
              </div>`,
        })}
        position={[props.marker.lat, props.marker.lon]}
        eventHandlers={{
          click: (e) => {
            map.flyTo(e.latlng);
          },
        }}
      >
        <MarkerPopup {...props.marker} />
      </Marker>
    </div>
  );
}
